/*eslint-disable*/
//orderPayStatus (integer, optional): 订单支付状态 0:未支付 ,1:已支付 ,2:已取消,3:支付失败 ,
export const orderStatus={
  0:"待支付",
  1:"已支付",
  2:"已取消",
  3:"支付失败"
}

export const statusStyle={
  0:"color:#FF2342;",
  1:"color:#3377FF;",
  2:"color:#aaa;",
  3:"color:#FFAA1E;"
}
