import { getTargetDate } from './time';

const moment = require('moment-timezone');

export const TZ_NAME_EN = moment.tz.guess(true);
export const TZ_IS_DST = moment().isDST();
export const TZ_NAME_EN_CM = `${TZ_NAME_EN}${TZ_IS_DST ? '夏令时' : ''} 时区`;
export const TIMESTEMP_CN = 480 * 60 * 1000;
export const UTC_OFFSET = moment().utcOffset();
export const formatString = 'YYYY-MM-DD HH:mm:ss';

export function dateFormat(d) {
  return moment(d).format('YYYY-MM-DD');
}
export function timeFormat(t) {
  return t ? moment(t).format(formatString) : t;
}
export function checkIsMoment(obj) {
  return moment.isMoment(obj);
}
export function getNextTime(next, type) {
  const momentN = moment().add(next, 'd');
  const timeStr = type === 'start' ? '00:00:00' : '23:59:59';
  const year = moment(momentN).year();
  return `${year}-${getTargetDate(next)} ${timeStr}`;
}
export function userCurrentTimestemp(next) {
  const str = getNextTime(next, 'start');
  return (moment(str).utc().valueOf());
}
export function userCurrentEndTimestemp(next) {
  const str = getNextTime(next, 'end');
  return (moment(str).utc().valueOf());
}
export function transferCNTimeToLocal(time,str) {
  let formatstr=str||formatString
  const cnUTCTime = moment.tz(time, 'Asia/Shanghai').utc();
  return moment.utc(cnUTCTime).local().format(formatstr);
}
export function localBeginTime(time, str) {
  let formatstr=str||formatString
  const cnUTCTime = moment.tz(time, 'Asia/Shanghai').utc();
  return moment.utc(cnUTCTime).local().format(formatstr);
}
export function formatSafariTime(dateStr) {
  const isSafari = /Apple/.test(navigator.vendor);
  return isSafari ? new Date((dateStr).replace(/-/g, '/')) : new Date(dateStr);
}
export function localShowTime(start, end) {
  // 慎用toLocaleTimeString，语言不同，返回的字符串不一致
  const startN = transferCNTimeToLocal(start);
  const endN = end ? transferCNTimeToLocal(end) : null;
  const sDate = startN ? formatSafariTime(startN) : null;
  const week = sDate.getDay();
  const hour = sDate.getHours();
  const minute = sDate.getMinutes();
  const minuteNew = minute.toString().length < 2 ? `${minute}0` : minute;
  const eDate = endN ? formatSafariTime(endN) : null;
  const eHour = eDate ? eDate.getHours() : '';
  const eMinute = eDate ? eDate.getMinutes() : '';
  const eMinuteNew = eMinute ? (eMinute.toString().length < 2 ? `${eMinute}0` : eMinute) : '00';
  const obj = {
    0: '日', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六',
  };
  return `周${obj[week]}${hour > 12 ? '下午' : '上午'} ${hour > 12 ? hour - 12 : hour}:${minuteNew}-${eHour > 12 ? eHour - 12 : eHour}:${eMinuteNew}`;
}

export function localShowTimeNew(start, end) {
  // 慎用toLocaleTimeString，语言不同，返回的字符串不一致
  const startN = transferCNTimeToLocal(start);
  const endN = end ? transferCNTimeToLocal(end) : null;
  const sDate = startN ? formatSafariTime(startN) : null;
  const week = sDate.getDay();

  const hour = sDate.getHours();
  const minute = sDate.getMinutes();

  const minuteNew = minute.toString().length < 2 ? `${minute}0` : minute;

  const eDate = endN ? formatSafariTime(endN) : null;

  const eHour = eDate ? eDate.getHours() : '';
  const eMinute = eDate ? eDate.getMinutes() : '';

  const eMinuteNew = eMinute ? (eMinute.toString().length < 2 ? `${eMinute}0` : eMinute) : '00';
  const obj = {
    0: '日', 1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六',
  };
  return {
    start: {
      hour,
      minute: minuteNew,
    },
    end:{
      hour: eHour,
      minute: eMinuteNew,
    }
  };
}

export const isCN = () => {
  let zone = moment.tz.guess(true)
  return ['Asia/Shanghai', 'Asia/Taipei', 'Asia/Hong_Kong', 'Asia/Macau', 'Asia/Urumqi'].includes(zone)
}


export const showBjTime = (time, formatStr) => {
  let str = formatStr || formatString
  return moment.tz(time, "Asia/Shanghai").format(str)
}