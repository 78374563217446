<template>
  <div class="myPage" :style="paginationStyle">
    <div class="page-num">
      共
      <div class="page">
        <span>{{ curPage }}</span
        >/{{pages}}
      </div>
      页
    </div>
    <div class="page-btn">
      <img :class="preDisabled ? 'disabled' : ''" @click="prePage" :src="pre" alt="" />
      <div class="center">{{ curPage }}</div>
      <img :class="nextDisabled ? 'disabled' : ''" @click="nextPage" :src="next" alt="" />
    </div>
    <div class="page-jump">前往 <el-input
      @keyup.enter="changePage"
      @blur="changePage" v-model="curPage" />页</div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    paginationStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pre: require('@/assets/img/calendar_pre.png'),
      next: require('@/assets/img/calendar_next.png'),
      curPage: this.page,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.limit) || 1;
    },
    preDisabled() {
      return this.curPage === 1;
    },
    nextDisabled() {
      return this.curPage === this.pages;
    },
  },
  methods: {
    prePage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
        this.changePage();
      }
    },
    nextPage() {
      if (this.curPage < this.pages) {
        this.curPage += 1;
        this.changePage();
      }
    },
    changePage() {
      const page = typeof this.curPage === 'number' ? this.curPage : Number(this.curPage);
      if (page > this.pages) this.curPage = this.pages;
      if (!page) this.curPage = 1;
      this.$emit('changePage', this.curPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.myPage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #72777e;
  font-size: 18px;
  margin-top: 20px;
  .page-num {
    display: flex;
    .page {
      margin: 0 5px;
      span {
        color: #3377ff;
      }
    }
  }
  .page-btn {
    margin: 0 40px;
    display: flex;
    align-items: center;
    img {
      width: 34px;
      height: 34px;
      cursor: pointer;
      &.disabled{
        cursor: not-allowed;
      }
    }
    .center {
      width: 40px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #131b33;
      border-radius: 12px;
      margin: 0 20px;
      color: #fff;
    }
  }
  .page-jump {
    display: flex;
    align-items: center;
    .el-input {
      margin: 0 20px;
      width: 50px;
    }
  }
}
</style>
