<template>
  <div class="myTable">
    <el-table :data="tableData" :style="tabStyle" :fit="true">
      <template #empty>
        <p class="empty_tip">{{emptyText||'暂无数据'}}</p>
        <slot></slot>
      </template>
      <el-table-column v-if="indexAble" align="center" type="index" width="80" label="序号" />
      <el-table-column
        v-for="(item, i) in columns"
        :key="i"
        :prop="item.prop"
        :label="item.label"
        :width="item.width || 'auto'"
        :render="item.render"
        :show-overflow-tooltip="item.showToolTip">
        <template #default="scope" v-if="item.render">
          <span :style="wrapStyle(item,scope.row)">
            {{ item.render(scope.row) }}
          </span>
        </template>
        <template #default="scope" v-else-if="item.renderDom">
          <div :style="wrapStyle(item,scope.row)" v-html="item.renderDom(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column v-if="handleArr.length" label="操作"  width="240px" class="optColumn">
        <template #default="scope">
          <p style="height:42px;">
            <el-button
              type="text"
              :style="'color:' + subItem.color+';'"
              :disabled="wrapDisabled(subItem,scope.row)"
              v-for="(subItem, subI) in handleArr"
              v-show="isShow(subItem.visible, scope.row)"
              :key="subI"
              @click="handleAction(subItem,scope.row)">
              {{ subItem.text }}
            </el-button>
          </p>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <hcp-pagination v-if="isPage" @changePage="changePage" :total="total" />
</template>

<script>
import HcpPagination from './HcpPagination.vue';

export default {
  components: { HcpPagination },
  name: 'MyTable',
  props: {
    emptyText: String,
    columns: Array,
    tableData: Array,
    handleArr: {
      type: Array,
      default: () => [],
    },
    isPage: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    indexAble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabStyle: {
        maxWidth: '948px',
        minHeight: '600px',
        overflow: 'hidden',
      },
    };
  },
  methods: {
    isShow(visible, row) {
      let isShow = false;
      if (visible) {
        isShow = visible(row);
      } else {
        isShow = true;
      }
      return isShow;
    },
    changePage(page) {
      this.$emit('changePage', page);
    },
    handleAction(item, row) {
      if (item.action) {
        item.action(row);
      } else {
        this.$emit('handle', item.text, row);
      }
    },
    wrapDisabled(item, row) {
      const d = item.disabled;
      if (typeof d === 'boolean') {
        return !!d;
      } if (typeof d === 'function') {
        return d(row);
      }
      return false;
    },
    wrapStyle(item, row) {
      const s = item.style;
      if (typeof s === 'string') {
        return s;
      } if (typeof s === 'function') {
        return s(row);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.myTable {
  box-shadow: 0px 2px 16px 0px rgba(202, 205, 214, 0.5);
  background-color: #fff;

  .empty_tip{
    margin-top:95px;
    font-size: 16px;
    font-weight: 400;
    color: #CACDD6;
    line-height: 22px;

  }
  .el-table th > .cell{
    font-size: 17px;
    font-weight: 400;
    color: #6D7278;

  }
  .el-table,
  .cell {
    font-size: 16px;
    font-weight: 500;
    color: #333B50;
  }
  .el-table td, .el-table th.is-leaf{
    border:none !important;
  }
  .optColumn{
    display:flex;
    flex-flow: row wrap;
  }
}
</style>
