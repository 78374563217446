<template>
  <div class="myTabs">
    <ul>
      <li
        :class="{ active: activeTab === item.value, hover: type === 'practice' }"
        :style="customStyle"
        v-for="(item, i) in tabs"
        :key="i"
        @click="type === 'practice' ? '' : clickTab(item.value)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "myTabs",
  props: {
    tabs: Array,
    activeTab: {
      type: [String, Number],
      default: "",
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    clickTab(val) {
      this.$emit("update:activeTab", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.myTabs ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(120, 154, 172, 0.18);
    color: #a0a2ab;
    padding: 0 30px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    &.active {
      background: linear-gradient(52deg, #ff3366 0%, #ffaa1e 100%);
      color: #fff;
    }
    &.hover {
      cursor: not-allowed;
    }
  }
}
</style>
