/* eslint-disable */
export function formatDate(date, fmt) {
  date = new Date(date || new Date());
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = `${o[k]}`;
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
export function padLeftZero(str) {
  str = String(str);
  return ('00' + str).substr(str.length)
}

// 获取当天开始的一周的日期，next当天为0
export function getTargetDate(next) {
  let date = new Date().getDate();
  let current = new Date(new Date().setDate(date + next))
  let month = current.getMonth() + 1;
  let day = current.getDate();
  return addComplement(month) + '-' + addComplement(day)
}

//日期补位 03-03
export function addComplement(num) {
  let str = typeof num === "string" ? num : num.toString();
  return str.length < 2 ? "0" + str : str
}

//获取当天开始的一周的星期，next当天为0
export function getTargetDay(next) {
  let obj = { 0: '周日', 1: "周一", 2: "周二", 3: "周三", 4: "周四", 5: "周五", 6: "周六" }
  let day = new Date().getDay() + next;
  let _num = day % 7;
  return obj[_num]
}
