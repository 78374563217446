<template>
  <div class="user_orders_page_container">
    <my-tabs :tabs="tabs" v-model:activeTab="activeTab" class="tabs"></my-tabs>
    <div class="orders_table_container">
      <my-table
        empty-text="暂无数据"
        :columns="columns"
        :is-page="true"
        :table-data="ordersData.list || []"
        :total="ordersData.total"
        :handle-arr="handleActions"
        @changePage="changePage"
      >
      </my-table>
    </div>
    <el-dialog
      title="操作提示"
      v-model="dialogVisible"
      width="30%"
      :center="true"
      :before-close="handleClose"
    >
      <p class="cancel_pay_text">确认取消订单？</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            class="footer_btn"
            type="primary"
            plain
            @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button class="footer_btn" type="primary" @click="confirmCancel"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="请选择支付方式"
      v-model="payDialogVisible"
      width="30%"
      :center="true"
      :before-close="handleClose"
    >
      <div class="pay_dialog_content">
        <div
          class="pay_type_wrapper"
          :class="activePayType === 2 ? 'active_pay_type' : null"
          @click="activePayType = 2"
        >
          <i class="wechat"></i>微信支付
        </div>
        <div
          class="pay_type_wrapper"
          :class="activePayType === 1 ? 'active_pay_type' : null"
          @click="activePayType = 1"
        >
          <i class="ali"></i>支付宝支付
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            class="footer_btn"
            type="primary"
            plain
            @click="payDialogVisible = false"
            >取 消</el-button
          >
          <el-button class="footer_btn" type="primary" @click="confirmPay"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { timeFormat } from "@/utils/timezone";
import MyTabs from "@/components/MyTabs.vue";
import MyTable from "@/components/MyTable.vue";
import { connectState } from "@/utils/wyUtil";
import { orderStatus, statusStyle } from "./config";

export default {
  components: { MyTabs, MyTable },
  data() {
    return {
      dialogVisible: false,
      activeRow: {},
      tabs: [
        // optional): 订单支付状态 0:未支付 ,1:已支付 ,2:已取消 ,3:支付失败 ,查全部的时候不传
        { label: "全部订单", value: 0, status: "" },
        { label: "待付款", value: 1, status: 0 },
        { label: "已付款", value: 2, status: 1 },
        { label: "已取消", value: 3, status: 2 },
      ],
      activeTab: 0,
      columns: [
        {
          prop: "orderCode",
          label: "订单编号",
          showToolTip: true,
          width: 150,
        },
        {
          prop: "itemName",
          label: "商品名称",
          showToolTip: true,
          width: 150,
        },
        {
          prop: "price",
          label: "实付金额",
          width: 90,
          render: (row) => `¥${Number(row.price).toFixed(2)}`,
        },
        {
          prop: "createDate",
          label: "下单时间",
          render: (row) => timeFormat(row.createDate),
          showToolTip: true,
          width: 130,
        },
        {
          prop: "orderPayStatus",
          label: "状态",
          width: 80,
          render: (row) => orderStatus[row.orderPayStatus],
          style: (row) => statusStyle[row.orderPayStatus],
        },
      ],
      handleActions: [
        // 订单支付状态 0:未支付 ,1:已支付 ,2:已取消,3:支付失败 ,
        {
          text: "取消",
          visible: (row) => row.orderPayStatus === 0,
          action: (row) => this.handleCancelPay(row),
          color: "#6D7278",
        },
        {
          text: "立即支付",
          visible: (row) => {
            const { itemCatalog, orderPayStatus } = row;
            return (
              orderPayStatus === 0 && ![0, 1, 6, 18, 20].includes(itemCatalog)
            );
          },
          action: (row) => this.handleToPay(row),
        },
        {
          text: "详情",
          visible: (row) => {
            const { itemCatalog, orderPayStatus } = row;
            return (
              orderPayStatus === 1 && ![1, 6, 18, 20].includes(itemCatalog)
            );
          },
          action: (row) => this.handleToDetail(row),
        },
        // {
        //   text: "我要评价",
        //   visible: (row) => {
        //     const { ifComment, orderPayStatus } = row;
        //     return orderPayStatus === 1 && !ifComment;
        //     // return orderPayStatus === 1;
        //   },
        //   action: (row) => this.handleToAddComment(row),
        // },
        {
          text: "查看协议",
          visible: (row) => {
            return row.orderPayStatus === 1 && row.ifInvoice;
          },
          action: (row) => this.handleView(row),
        },
        {
          text: "已评价",
          visible: (row) => {
            const { ifComment, orderPayStatus } = row;
            return orderPayStatus === 1 && ifComment;
            // return orderPayStatus === 1;
          },
          disabled: true,
        },
      ],
      curPage: 1,
      limit: 10,
      payDialogVisible: false,
      activePayType: 2,
    };
  },
  computed: {
    ...connectState("order", ["ordersData"]),
  },
  watch: {
    activeTab(val) {
      this.activeTab = val;
      this.getOrdersData(this.tabs[val].status);
    },
  },
  created() {
    let { tab = 0 } = this.$route.query;
    tab = typeof tab === "string" ? Number(tab) : tab;
    this.activeTab = tab;
    this.getOrdersData(this.tabs[tab].status);
  },
  methods: {
    handleView(row) {
      this.$router.push(
        `/viewAgreement?type=0&code=${row.orderCode}&catalog=${row.itemCatalog}`
      );
    },
    handleClose(done) {
      this.activeRow = {};
      this.activePayType = 2;
      done();
    },
    changePage(page) {
      this.curPage = page;
      this.getOrdersData(this.tabs[this.activeTab].status);
    },
    getOrdersData(status) {
      const params = {
        curPage: this.curPage,
        limit: this.limit,
      };
      if ([0, 1, 2, 3].includes(status)) {
        params.orderPayStatus = status;
      }
      this.$store.dispatch("order/getOrderList", params);
    },
    handleCancelPay(row) {
      this.dialogVisible = true;
      this.activeRow = row;
    },
    handleToPay(row) {
      this.payDialogVisible = true;
      this.activeRow = row;
    },
    confirmPay() {
      if (![1, 2].includes(this.activePayType)) {
        this.$message.error("请选择支付方式!");
        return false;
      }
      const row = this.activeRow;
      this.$store.dispatch("order/getPaySign", {
        appChannel: this.activePayType === 2 ? 22 : 21,
        orderCode: row.orderCode,
        payType: this.activePayType,
        price: row.price,
        returnUrl: `${window.location.origin}/result?orderCode=`,
        callback: () => {
          this.payDialogVisible = false;
        },
      });
      return true;
    },
    confirmCancel() {
      this.$store.dispatch("order/userCancelOrder", {
        orderCode: this.activeRow.orderCode,
        callback: () => {
          this.dialogVisible = false;
          this.$message({ type: "success", message: "您已成功取消订单" });
          this.getOrdersData(this.tabs[this.activeTab].status);
        },
      });
    },
    handleToDetail(row) {
      this.$router.push(`/orderDetail/${row.orderCode}`);
    },
    handleToAddComment(row) {
      this.$router.push(`/addComment/${row.orderCode}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.user_orders_page_container {
  width: 948px;
  min-height: 500px;
  padding-bottom: 50px;
  overflow-x: hidden;
  .tabs {
    margin-bottom: 15px;
  }
  .orders_table_container {
    border-radius: 2px;
    overflow: hidden;
  }
  .cancel_pay_text {
    padding: 0 10%;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    color: #4e596f;
    line-height: 26px;
  }
  .dialog-footer {
    margin-bottom: 10px;
    .footer_btn {
      margin: 0 10px;
      width: 88px;
      border-radius: 3px;
      font-weight: 600;
    }
  }
  .pay_dialog_content {
    padding: 20px;
    height: 60px;
    line-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .pay_type_wrapper {
      width: 168px;
      height: 60px;
      line-height: 60px;
      background: #ffffff;
      border-radius: 3px;
      font-size: 18px;
      font-weight: 500;
      color: #333b50;
      border: 2px solid #cacdd6;
      cursor: pointer;

      i {
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
      }
      .wechat {
        width: 29px;
        height: 24px;
        background: url("../../assets/img/wx_pay.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .ali {
        width: 27px;
        height: 27px;
        background: url("../../assets/img/ali_pay.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    .active_pay_type {
      border-color: #3377ff;
    }
  }
}
</style>
